.renewalForm {
    min-height: 120vh;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.field {
    margin-bottom: 5vmin;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.input {
    /* margin-top: 1vmin; */

    min-width: 50vw;
    max-width: 60%;

    /* border-style: solid;
    border-radius: 1vmin; */

    /* padding: 2vmin; */

    /* font-size: 1.2rem; */
}

.submitButton {
    /* padding: 1rem; */

    /* font-size: 1.2rem; */

    margin-bottom: 5vmin;
}